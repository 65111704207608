import Image from 'next/image';
import React from 'react';

function DarkLayout({ customStyle = '', children, grid3D = false, gridMode = false, id = '' }) {
  return (
    <div className={`relative grid items-center bg-black ${customStyle}`} id={id}>
      <div
        className={`absolute inset-0 hidden h-full w-full ${
          grid3D || gridMode ? 'opacity-5' : 'opacity-100'
        } md:block`}
      >
        <Image
          src={
            grid3D
              ? '/assets/3d-grid.webp'
              : gridMode
              ? '/assets/grid.webp'
              : '/assets/refer-and-earn/Dot grid.png'
          }
          objectFit="cover"
          className="h-full w-full"
          layout="fill"
          priority
        />
      </div>
      {children}
    </div>
  );
}

export default DarkLayout;
