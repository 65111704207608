import React from 'react';
import cn from 'classnames';

function NewSectionTitle({ headingStyle, text, highlightedText, underlineStyle }) {
  return (
    <h2
      className={cn(
        'flex flex-wrap items-center justify-center gap-[8px] text-center font-satoshi-medium font-satoshi-medium text-[30px] font-semibold font-bold leading-9 md:font-satoshi-bold md:font-satoshi-bold md:text-[36px]  md:leading-none',
        headingStyle
      )}
    >
      {text}{' '}
      {highlightedText && (
        <span
          className={cn('relative -top-3 text-red underline underline-offset-8', underlineStyle)}
        >
          {highlightedText}
        </span>
      )}
    </h2>
  );
}

export default NewSectionTitle;
