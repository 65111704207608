import moment from 'moment';
import Link from 'next/link';
import useStorage from '../../../utils/hooks/useStorage';
import Image from 'next/image';
import { getImgUrl } from '@utils/functions/getImgUrl';
import { PLAN_TYPE } from '@lib/constants';
import cn from 'classnames';

import woolfBg from '@assets/partners-bg/woolf-bg.webp';
import iitBg from '@assets/partners-bg/new-IIT-Guwahati-bg.webp';
import { Primary, Secondary } from 'stories/Button.stories';

import iitLogo from '@assets/new-iitg-short-logo.png';
import woolfNewLogo from '@assets/woolf-new.png';

function CourseCard({
  homepage = false,
  course,
  index,
  newLayout = false,
  horizontalView = false,
}) {
  // get utm from storage
  const { getItem } = useStorage();
  const currentUtm = getItem('utm') && JSON.parse(getItem('utm'));
  // set query string for redirect
  let queryString = '';
  for (const property in currentUtm) {
    queryString += `&${property}=${currentUtm[property]}`;
  }
  let courseDates = course?.upcomingBatches?.map((d) => moment(d.startDate, 'YYYY-MM-DD'));
  let courseStartDate = moment.min(courseDates)?._i;
  courseStartDate = moment(courseStartDate).format('MMMM Do YYYY');

  const { DetailsMetric, buttonLink, buttonText, courseDesc } = course?.courseDetails || {};

  return (
    <>
      {newLayout ? (
        <NewCourseCard
          courseName={course.courseName}
          slug={course.slug}
          planType={course.planType}
          DetailsMetric={DetailsMetric}
          horizontalLayout={horizontalView}
        />
      ) : (
        <div
          className={`mt-14 flex flex-col items-start justify-between space-y-3 rounded border border-gray-light/50 bg-white p-5 shadow-xl md:h-[27rem] `}
        >
          <h6 className="text-xl font-black leading-7">{course.courseName}</h6>
          <hr className="w-full border-gray-light" />
          <p className="mt-3  text-[0.9rem] font-light leading-6 md:h-[7.5rem]">
            {course?.courseDesc}
          </p>

          <div className="!mt-6 grid grid-cols-2 gap-4">
            {DetailsMetric?.map((item) => (
              <section key={item.title} className="flex items-center gap-2">
                <img src={getImgUrl(item.icon)} className="h-6" alt="icon" />
                <p className="w-full text-[0.8rem] leading-4 text-[#545454]">{item.title}</p>
              </section>
            ))}
          </div>
          <Link
            href={
              course?.planType === PLAN_TYPE.MASTERS_DEGREE
                ? `/degrees/${course?.slug}`
                : `/courses/${course?.slug}`
            }
            as={
              course?.planType === PLAN_TYPE.MASTERS_DEGREE
                ? `/degrees/${course?.slug}`
                : `/courses/${course?.slug}`
            }
          >
            <a className={`!mt-7 flex w-full items-center  justify-center bg-red p-2 text-white`}>
              Explore Course
            </a>
          </Link>
        </div>
      )}
    </>
  );
}
export default CourseCard;

function NewCourseCard({
  courseName,
  slug,
  planType,
  DetailsMetric = [],
  horizontalLayout = false,
}) {
  const courseBackground = planType === PLAN_TYPE.MASTERS_DEGREE ? woolfBg : iitBg;

  const link = planType === PLAN_TYPE.MASTERS_DEGREE ? `/degrees/${slug}` : `/courses/${slug}`;

  const COURSE_MAP = {
    [PLAN_TYPE.MASTERS_DEGREE]: 'Masters Course',
    [PLAN_TYPE.PG_CERTIFICATION]: 'Certifications Course',
  };

  const LOGO_MAP = {
    [PLAN_TYPE.MASTERS_DEGREE]: {
      logo: woolfNewLogo,
      height: 40,
      width: 200,
    },
    [PLAN_TYPE.PG_CERTIFICATION]: {
      logo: iitLogo,
      height: 50,
      width: 180,
    },
  };

  const { logo, height, width } = LOGO_MAP[planType];

  return (
    <div
      className={cn('w-full gap-[24px] overflow-hidden rounded-2xl bg-[#1A1A1A]', {
        'grid md:col-span-2 md:grid-cols-2 md:items-center': horizontalLayout,
        'flex flex-col': !horizontalLayout,
      })}
    >
      <div className="relative">
        <Image src={courseBackground} layout="responsive" objectFit="cover" />
        <div className="absolute bottom-3 left-3 z-10 flex items-center gap-x-[8px]">
          <Image
            src={logo}
            height={height}
            width={width}
            objectFit="cover"
            className="overflow-hidden rounded-lg"
          />
          {/* {planType === PLAN_TYPE.MASTERS_DEGREE && (
            <>
              <span className="text-white">X</span>

              <Image
                src={LOGO_MAP[PLAN_TYPE.PG_CERTIFICATION]}
                height={40}
                width={200}
                objectFit="cover"
              />
            </>
          )} */}
        </div>
        <div className="absolute inset-0 h-full w-full bg-black bg-opacity-40"></div>
      </div>

      <div
        className={cn('flex flex-col items-start gap-y-[24px] px-[20px] pb-[20px]', {
          'md:!pb-0': horizontalLayout,
        })}
      >
        <div
          className={cn({
            'flex flex-col-reverse gap-y-[16px] gap-x-[8px] md:grid md:grid-cols-7':
              !horizontalLayout,
            'flex flex-col-reverse gap-y-[16px]': horizontalLayout,
          })}
        >
          <h2 className="col-span-4 font-satoshi-bold text-[20px] leading-7 text-white">
            {courseName}
          </h2>

          <Secondary
            label={COURSE_MAP[planType] || 'Masters Coruse'}
            size={'small'}
            customStyle={
              '!bg-[#ffffff1a] !text-white hover:!bg-[#ffffff1a] !border-none !outline-none self-start col-span-3'
            }
          />
        </div>

        <div className="grid grid-cols-2 gap-[24px]">
          {DetailsMetric?.map((item) => (
            <section key={item.title} className="flex items-center gap-x-[8px]">
              <img src={getImgUrl(item.icon)} className="h-5 w-5 object-contain" alt="icon" />
              <p className="font-satoshi-medium text-[14px] leading-5 text-white">{item.title}</p>
            </section>
          ))}
        </div>

        <Primary
          linkCTA
          label={'Explore Course'}
          size={'large'}
          href={link}
          customStyle={'!w-full'}
        />
      </div>
    </div>
  );
}
