import React, { useState, useEffect } from 'react';
import { fetchEventData } from '../BytesWidget';
import useEventCountDown from '@components/reuseable/EventCountDown';
import { eventDateTimeFormat } from '@utils/functions/readingTime';
import DeskView from './EventBannerDesk';
import { useRouter } from 'next/router';
import useStorage from '@utils/hooks/useStorage';

function EventBanner() {
  const [events, setEvents] = useState(null);

  const eventStartTime = eventDateTimeFormat(events?.eventDate, events?.startTime);
  const eventEndTime = eventDateTimeFormat(events?.eventDate, events?.endTime);
  const { showJoin, isEnded } = useEventCountDown(eventStartTime, eventEndTime);

  const { query, pathname } = useRouter();

  const { getItemFromLocal } = useStorage();

  const isRegisteredExist = getItemFromLocal('isRegistered');
  const registeredEventId = (isRegisteredExist && JSON.parse(isRegisteredExist)) || [];

  useEffect(() => {
    (async () => {
      const res = await fetchEventData(query?.courseSlug, pathname);

      if (!res) {
        setEvents(null);
        return;
      }

      const eventLiveTime =
        eventDateTimeFormat(res?.eventDate, res?.startTime)?.getTime() - 30 * 60 * 1000;

      if (registeredEventId?.includes(res?.eventId) && eventLiveTime > Date.now()) {
        setEvents(null);
      } else {
        setEvents(res);
      }
    })();
  }, [query]);

  const PATH_MAP = {
    AMA: '/events/master-class/',
    MasterClass: '/events/master-class/',
  };

  const path = PATH_MAP[events?.EventType || events?.eventType] || '/events/';

  return (
    <>
      {events && !isEnded && (
        <DeskView
          events={events}
          showJoin={showJoin}
          path={path}
          registeredEventId={registeredEventId}
        />
      )}
    </>
  );
}

export default EventBanner;
